<template>
  <div class="row vacancy-check-general-info">
    <div class="col-12">
      <p class="font-21 mb-3">
        Report Timing
      </p>
      <p>
        We're able to fulfil the report at least 1 day in advance
      </p>
    </div>
    <div class="col-12 col-md-4 pt-0">
      <ElFormItem prop="selected_date">
        <SdFloatLabel>
          <ElDatePicker
            ref="datePicker"
            v-model="reportPayloadRef.selected_date"
            type="date"
            :picker-options="{disabledDate}"
            :clearable="false"
            align="left"
            class="availability-date-picker"
            format="dd MMMM"
            placeholder="Select a date"
            @change="emitPayloadChanged"
          />
        </SdFloatLabel>
      </ElFormItem>
    </div>
    <ElDivider />
    <p class="col-12 font-21 py-0">
      Special instructions
    </p>
    <GeneralNotes
      class="col-12 pt-0"
      :general-notes.sync="reportPayloadRef.instructions"
      tooltip-text="Any additional instructions to our agent or emphasis on items to review during the report"
      placeholder-text="Notes or any specific things the agent should check"
      field-name="instructions"
      @change="emitPayloadChanged"
    />
    <div
      class="col-12 pt-4"
    >
      <ElFormItem
        id="checkbox-agreement"
        prop="agreementChecked"
      >
        <ElCheckbox
          v-model="reportPayloadRef.agreementChecked"
          :label="'By ordering the report, I confirm that the property will be ready for review\
          and no other vendors or contractors will be present. In case the Showdigs agent\
          will not be able to perform the report at the chosen time, a $'+vacancyCheckPrice+' fee will still\
          be charged.'"
          class="col-auto confirm-checkbox text-gray-darker"
          @change="emitPayloadChanged"
        />
      </ElFormItem>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { ref } from '@vue/composition-api';
import GeneralNotes from '@/components/common/GeneralNotes';
import { getPricesValueByKey } from '@/utils/PriceUtil';
import Price from '@/constants/Price';
import momentUtil from '../../utils/MomentUtil';

const earliestDay = momentUtil(null, momentUtil.tz.guess()).startOf('day').add(2, 'days').toDate();
const blockedDate = momentUtil(new Date('December 29, 2024'), 'UTC').startOf('day').toDate();

export default {
  name: 'ConditionReportVacancyCheckGeneralInfo',
  components: { GeneralNotes },
  props: {
    property: {
      type: Object,
      default: null,
    },
    unit: {
      type: Object,
      default: null,
    },
    payload: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const vacancyCheckPrice = getPricesValueByKey(Price.VACANT_PROPERTY, props.property.market_id);
    const reportPayloadRef = ref(props.payload);

    return {
      reportPayloadRef,
      emitPayloadChanged,
      disabledDate,
      vacancyCheckPrice,
      formValidations,
      emitUnitPayloadChanged,
    };

    function disabledDate(date) {
      if (moment(date).isSame(moment(blockedDate), 'day')) {
        return true;
      }
      return date < earliestDay;
    }

    function emitPayloadChanged() {
      context.emit('update-payload', {
        selected_date: reportPayloadRef.value.selected_date,
        window_hour: reportPayloadRef.value.window_hour,
        instructions: reportPayloadRef.value.instructions,
        agreementChecked: reportPayloadRef.value.agreementChecked,
        price: vacancyCheckPrice,
      });
    }

    async function emitUnitPayloadChanged() {
      await context.root.$store.dispatch('Unit/update', {
        unitId: props.unit.id,
        unit: props.unit,
      });

      emitPayloadChanged();
    }
  },
};

export const generalInfoRules = {
  selected_date: [
    {
      required: true,
      message: 'Date is reqired',
    },
  ],
  agreementChecked: [
    {
      required: true,
      type: 'enum',
      enum: ['true'],
      message: 'Agreement checked is required',
      transform: (value) => value.toString(),
    },
  ],
};

const formValidations = {
  ...generalInfoRules,
};
</script>

<style lang="scss">
.vacancy-check-general-info {
  #report_date_time_pickers {
    .el-input__prefix {
      display: none;
    }
    .el-form-item__error {
      left: 15px;
      top: 80%;
    }
  }

  .confirm-checkbox {
    background-color: theme-color('primary-superlight');
    border-radius: $app-border-radius;
    padding: 22px;
    .el-checkbox__label {
      white-space: normal;
      line-height: 1.375rem;
    }
    .el-checkbox__input {
      vertical-align: top;
      top: 2px;
    }
  }
  #checkbox-agreement {
    .el-form-item__error {
      top: 95%;
    }
  }
  .availability-date-picker {
    width: 100% !important;
    .el-input__prefix {
      display: none;
    }
    .el-input__inner {
      padding-left: 1rem;
    }
  }
  .condition-report-cost {
    background-color: theme-color('primary-superlight');
    height: 100px;
    border-radius: $app-border-radius;
  }
  .small-apt {
    right: 19px;
    top: -25px;
  }
  .large-apt {
    right: 20px;
    top: 6px;
  }
}

</style>
